const GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE = 'False';

export const GTM_CLICK_ON_MM_LOGO = () => ({
  event: 'interaction_click on MM logo',
  e_category: 'navigation',
  e_action: 'Click on MM logo',
  e_label: 'header b2b',
  amp_event_name: 'Click on MM logo',
  event_trigger: 'header b2b',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_ACCOUNT_ACCESS = () => ({
  event: 'interaction_click on access account',
  e_category: 'navigation',
  e_action: 'Click on access account',
  e_label: 'header b2b',
  amp_event_name: 'Access account',
  event_trigger: 'header b2b',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_SUPPORT_BUTTON = () => ({
  event: 'interaction_click on call advisor',
  e_category: 'navigation',
  e_action: 'Click on call advisor header',
  e_label: 'call advisor header',
  amp_event_name: 'Call advisor',
  event_trigger: 'header b2b',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_SUPPORT_BUTTON_MD = () => ({
  event: 'interaction_click on support block',
  e_category: 'navigation',
  e_action: 'Click on support block',
  e_label: 'support block',
  amp_event_name: 'Advisor block',
  event_trigger: 'header b2b',
  interaction_hit: GOOGLE_ANALYTICS_INTERACTION_HIT_FALSE,
});

export const GTM_CLICK_ON_MMPRO_LOGO = (pageName?: string) => ({
  event: 'interaction_click B2B club',
  e_category: 'navigation',
  e_action: 'Click on B2B club',
  amp_event_name: 'Click on B2B club', // Amplitude Event name
  event_trigger: 'Header', // Return the page type
  page_name: pageName,
});
