import { useSelector } from 'react-redux';
import { twJoin } from 'tailwind-merge';

import { formatCurrency } from '@/domains/core/localization/currency';
import { pageNameSelector } from '@/domains/core/page/page.selectors';
import { Link } from '@/domains/core/routing/components/Link';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { getPlatform } from '@/domains/core/settings/utils';
import { Skeleton } from '@/domains/core/tamagoshiTailwind/components/Skeleton/Skeleton';
import { Div } from '@/domains/core/tamagoshiTailwind/components/Typography/Typography';
import { ManoClubColoredMLogo } from '@/domains/core/tamagoshiTailwind/logos/manoClub/ManoClubColoredMLogo';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { useLoyaltyProgramActivated } from '@/domains/loyalty/modules/LoyaltyProgram/hooks/useLoyaltyProgramActivated.default';
import { GTM_CLICK_ON_MMPRO_LOGO } from '@/domains/productDiscovery/HeaderLeonidas/b2b/tracking/gtmEvents';
import { useLoyaltyWalletAmountQuery } from '@/domains/shoppingCart/b2b/Cart/LoyaltyProgram/generated/loyaltyWallet.query.generated';
import { Platform } from '@/generated/graphql/types';

import { walletLabel } from './translations';

import styles from './WalletButton.module.scss';

export const WalletButton = () => {
  const pageName = useSelector(pageNameSelector);
  const { isLoyaltyProgramV2Enabled } = useLoyaltyProgramActivated();
  const [{ data, error, fetching }] = useLoyaltyWalletAmountQuery({
    requestPolicy: 'cache-and-network',
    variables: {
      platform: getPlatform().toUpperCase() as Platform,
    },
  });

  if (error) {
    return null;
  }

  return (
    <button
      type="button"
      className={twJoin(styles.root, 'px-s py-0 text-left')}
      data-testid="wallet-button"
    >
      <Link
        href={(routes as Record<string, string>).mySpaceAdvantages}
        className="flex h-[40px] items-center gap-s text-foundation-inverted"
        onClick={() => Gtm.push(GTM_CLICK_ON_MMPRO_LOGO(pageName))}
        data-testid="wallet-button-link"
      >
        {isLoyaltyProgramV2Enabled && (
          <ManoClubColoredMLogo className="hidden w-[50px] tablet:block" />
        )}
        <div className="flex flex-col justify-center">
          <Div weight="semibold" scale="small">
            {walletLabel}
          </Div>
          <div className="-mt-xs">
            {fetching ? (
              <Skeleton.Block
                width={50}
                height={20}
                data-testid="wallet-amount-loading"
              />
            ) : (
              <Div
                weight="semibold"
                scale="small"
                className={twJoin(
                  isLoyaltyProgramV2Enabled
                    ? 'text-loyalty-primary'
                    : 'text-accent-primary',
                )}
              >
                {formatCurrency(data?.loyaltyWallet?.amount || 0)}
              </Div>
            )}
          </div>
        </div>
      </Link>
    </button>
  );
};

WalletButton.displayName = 'WalletButton';
