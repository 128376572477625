import { useBooleanFlags } from '@/domains/core/flags/flags.hooks';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { LoyaltyHvcLevel } from '@/domains/customerManagement/auth/model';
import { LOYALTY_PROGRAM_HVC_FLAG } from '@/domains/loyalty/modules/LoyaltyProgramHvc/constants';

export const useLoyaltyProgramActivated = () => {
  const [isLoyaltyProgramHvcFlagEnabled] = useBooleanFlags([
    LOYALTY_PROGRAM_HVC_FLAG,
  ]);
  const { isGuest, loyalty_hvc_level } = useAuth();

  return {
    isLoyaltyProgramV1Enabled: false,
    isLoyaltyProgramV2Enabled: isLoyaltyProgramHvcFlagEnabled,
    isOptInLoyaltyProgramV2:
      isLoyaltyProgramHvcFlagEnabled &&
      !!loyalty_hvc_level &&
      loyalty_hvc_level !== LoyaltyHvcLevel.OPT_OUT,
    isOptOutLoyaltyProgramV2:
      isLoyaltyProgramHvcFlagEnabled &&
      ((!!loyalty_hvc_level && loyalty_hvc_level === LoyaltyHvcLevel.OPT_OUT) ||
        isGuest),
  };
};
