import { Property } from 'csstype';
import { CSSProperties, ReactNode } from 'react';

import {
  FONT_SCALES,
  LINE_HEIGHTS,
} from '@/core/tamagoshiTailwind/tokens/typography';
import { twMerge } from '@/core/tamagoshiTailwind/util/twMerge';

/** Props for the SkeletonGroup component.*/
type SkeletonGroupProps = {
  /** Additional class names to apply to the SkeletonGroup. */
  className?: string;
  children: ReactNode;
  'data-testid'?: string;
};

/**
 * A component that groups skeleton elements together.
 * There is a default gap of 8px between each child but you can override it by
 * passing a custom gap class name.
 */
const SkeletonGroup = ({
  className,
  children,
  'data-testid': testId,
}: SkeletonGroupProps) => (
  <div className={twMerge('flex gap-s', className)} data-testid={testId}>
    {children}
  </div>
);

SkeletonGroup.displayName = 'SkeletonGroup';

/** Props for the SkeletonBlock component. */
type SkeletonBlockProps = {
  /**
   * The height of the block.
   * Support all known font scales in addition to built-in keywords as well as
   * hardcoded values.
   */
  height?: Property.Height<number> | (typeof FONT_SCALES)[number];
  /** The width of the block */
  width?: CSSProperties['width'];
  'data-testid'?: string;
};

/** A component that renders a skeleton block with specified width and height. */
const SkeletonBlock = ({
  width = '100%',
  height = '100%',
  'data-testid': testId,
}: SkeletonBlockProps) => {
  return (
    <div
      className="animate-pulse rounded-card bg-foundation-disabled"
      style={{
        width,
        height:
          typeof height === 'string' && height in LINE_HEIGHTS
            ? LINE_HEIGHTS[height as keyof typeof LINE_HEIGHTS]
            : height,
      }}
      data-testid={testId}
    />
  );
};

SkeletonBlock.displayName = 'SkeletonBlock';

/** The Skeleton component, which includes Group and Block subcomponents. */
export const Skeleton = {
  Group: SkeletonGroup,
  Block: SkeletonBlock,
};
