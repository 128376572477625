import { Svg } from '@manomano-internal/tamagoshi-icons/esm/SvgOptimizer/Svg';

import { makeTamagoshiLogo } from '@/core/tamagoshiTailwind/util/makeTamagoshiComponent';

export const ManoClubColoredMLogo = makeTamagoshiLogo((props, ref) => (
  <Svg
    width="81"
    height="41"
    fill="none"
    viewBox="0 0 81 41"
    xmlns="http://www.w3.org/2000/svg"
    optimizationId="tama-mano-club-colored-m-logo"
    {...props}
    ref={ref}
  >
    <rect x=".242" y=".144" width="80" height="40" rx="20" fill="#FFED49" />
    <path
      d="M20.802 13.658c-1.62 0-2.874.61-3.763 1.832-.888 1.213-1.333 2.907-1.333 5.082 0 4.527 1.699 6.79 5.096 6.79 1.426 0 3.152-.356 5.18-1.069v3.61c-1.667.695-3.527 1.042-5.582 1.042-2.953 0-5.212-.893-6.776-2.68-1.565-1.796-2.347-4.37-2.347-7.72 0-2.11.384-3.958 1.152-5.54.769-1.593 1.87-2.81 3.305-3.653 1.444-.851 3.133-1.277 5.068-1.277 1.972 0 3.953.477 5.943 1.43l-1.388 3.5a22.983 22.983 0 0 0-2.291-.945 6.834 6.834 0 0 0-2.264-.402ZM33.443 30.945h-4.235V9.339h4.235v21.606ZM48.202 30.946l-.57-1.986h-.222c-.453.722-1.097 1.282-1.93 1.68-.833.389-1.782.583-2.846.583-1.824 0-3.199-.486-4.124-1.458-.926-.98-1.389-2.388-1.389-4.22V15.421h4.235v9.067c0 1.12.2 1.962.597 2.527.398.555 1.032.833 1.902.833 1.185 0 2.042-.393 2.57-1.18.527-.796.79-2.11.79-3.944v-7.303h4.236v15.524H48.2ZM63.766 15.131c1.833 0 3.267.718 4.304 2.152 1.037 1.426 1.555 3.384 1.555 5.874 0 2.564-.537 4.55-1.61 5.957-1.065 1.407-2.518 2.11-4.36 2.11-1.824 0-3.254-.662-4.291-1.985h-.292l-.708 1.708H55.13V9.34h4.235v5.027c0 .638-.056 1.661-.167 3.068h.167c.99-1.537 2.458-2.305 4.402-2.305Zm-1.361 3.388c-1.046 0-1.81.324-2.291.972-.482.639-.731 1.699-.75 3.18v.458c0 1.666.245 2.86.736 3.583.5.722 1.287 1.083 2.36 1.083.87 0 1.56-.398 2.07-1.195.518-.805.777-1.971.777-3.499 0-1.527-.26-2.67-.778-3.43-.518-.768-1.226-1.152-2.124-1.152Z"
      fill="#0C193A"
    />
    <path
      d="M.771 40.188a.432.432 0 0 1-.489-.601L5.28 28.65l8.523 8.57-13.03 2.968Z"
      fill="#FFED49"
    />
  </Svg>
));

ManoClubColoredMLogo.displayName = 'ManoClubColoredMLogo';
