import { Provider } from 'urql';

import { client } from '@/domains/core/graphqlClient/queryClient';
import { useAuth } from '@/domains/customerManagement/auth/hooks/useAuth';
import { useLoyaltyProgramActivated } from '@/domains/loyalty/modules/LoyaltyProgram/hooks/useLoyaltyProgramActivated.default';
import { useShouldDisplayWallet } from '@/domains/loyalty/modules/LoyaltyProgram/hooks/useShouldDisplayWallet';
import { UnauthenticatedWalletButton } from '@/domains/productDiscovery/HeaderLeonidas/components/WalletButton/UnauthenticatedWalletButton';
import { WalletButton } from '@/domains/productDiscovery/HeaderLeonidas/components/WalletButton/WalletButton';

export const WalletButtonContainer = () => {
  const { hasRetrievedAuthStatus, isAuthenticated } = useAuth();
  const shouldDisplayWallet = useShouldDisplayWallet();
  const { isLoyaltyProgramV2Enabled } = useLoyaltyProgramActivated();

  if (!hasRetrievedAuthStatus) {
    return null;
  }

  if (!isAuthenticated && isLoyaltyProgramV2Enabled) {
    return <UnauthenticatedWalletButton />;
  }

  if (isAuthenticated && shouldDisplayWallet) {
    return (
      <Provider value={client}>
        <WalletButton />
      </Provider>
    );
  }

  return null;
};

WalletButtonContainer.displayName = 'WalletButtonContainer';
