import { useSelector } from 'react-redux';
import { twJoin } from 'tailwind-merge';

import { pageNameSelector } from '@/domains/core/page/page.selectors';
import { Link } from '@/domains/core/routing/components/Link';
import { routes } from '@/domains/core/routing/routes/routes.default';
import { ManoClubColoredMLogo } from '@/domains/core/tamagoshiTailwind/logos/manoClub/ManoClubColoredMLogo';
import { Gtm } from '@/domains/core/tracking/utils/Gtm';
import { useMatchMedia } from '@/domains/core/ui/hooks/useMatchMedia';
import { GTM_CLICK_ON_MMPRO_LOGO } from '@/domains/productDiscovery/HeaderLeonidas/b2b/tracking/gtmEvents';

import styles from './WalletButton.module.scss';

export const UnauthenticatedWalletButton = () => {
  const pageName = useSelector(pageNameSelector);
  const isTabletOrBigger = useMatchMedia('tablet');

  if (!isTabletOrBigger) return null;

  return (
    <button
      type="button"
      className={twJoin(styles.root, 'px-s py-0 text-left')}
      data-testid="wallet-button"
    >
      <Link
        href={(routes as Record<string, string>).mySpaceAdvantages}
        className="flex h-[40px] items-center gap-s text-foundation-inverted"
        onClick={() => Gtm.push(GTM_CLICK_ON_MMPRO_LOGO(pageName))}
        data-testid="wallet-button-link"
      >
        <ManoClubColoredMLogo className="w-[50px]" />
      </Link>
    </button>
  );
};

UnauthenticatedWalletButton.displayName = 'UnauthenticatedWalletButton';
